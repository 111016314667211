.hero {
  height: 100vh;
  background-color: #edf5f8;
  position: relative;
  overflow: hidden;
  @include mq($until: sm) {
    height: 73vh;
  }

  &__images {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: #edf5f8;
        
    &:after {
      background-image: url('../../images/homepage/woda2.gif');
      background-position: bottom right;
      background-size: cover;
      background-repeat: no-repeat;
      z-index: 2;
      width: 100%;
      height: 90vh;
      margin-top: 20vh;
      position: absolute;
      display: block;
      content: '';
    }

    @include mq($until: md) {
      &:after {
        height: 73vh;
        margin-top: 27vh;
        background-size: 130%;
        background-position: bottom right;
      }
    }

    @include mq($until: sm) {
      &:after {
        height: 73vh;
        margin-top: 0;
      }
    }
  }

  &__bg, &__building {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: block;
  }

  &__bg {
    background-image: url('../../images/homepage/niebo-bg.jpg');
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    position: fixed;

    &:before {
      background-image: url('../../images/homepage/clouds1.png');
      position: absolute;
      top: 0;
      width: 100%;
      height: 100%;
      content: '';
      display: block;
      background-position: center;
      background-size: 100%;
      background-repeat: no-repeat;
      animation: MoveRight 12s linear infinite;
    }

    @include mq($until: md) {

    }

    @include mq($until: sm) {

    }
  }

  &__building {
    background-image: url('../../images/homepage/dom.png');
    background-position: bottom right;
    background-size: cover;
    background-repeat: no-repeat;
    z-index: 3;
    height: 90vh;
    margin-top: 20vh;

    @include mq($until: md) {
      height: 73vh;
      margin-top: 27vh;
      background-size: 130%;
      background-position: bottom right;
    }

    @include mq($until: sm) {
      height: 73vh;
      margin-top: 0;
    }
  }

  &__header {
    position: fixed;
    top: 2.5vw;
    left: 0;
    width: 100%;
    text-align: center;
    font-size: 4.5rem;
    font-weight: 600;
    letter-spacing: 0.15rem;
    color: #ffffff;
    text-shadow: 3px 3px 104px rgba(0, 0, 0, 0.13);
    z-index: 2;

    br {
      display: none;
    }

    @include mq($until: md) {
      top: 25vh; 
      left: 10vw;
      width: 100%;
      text-align: left;
      font-size: 6vw;

      br {
        display: initial;
      }
    }
    @include mq($until: sm) {
      font-size: 9vw;
      left: 5vw;
    }
  }
}

.h-services {
  margin-top: rem(125px);
  padding-bottom: rem(125px);
  scroll-snap-align: start;
  @include mq($until: md){
    margin-top: rem(65px);
  }
  @include mq($until: xs) {
    padding-bottom: rem(65px);
  }

  &__header {
    position: relative;

    &::before {
      height: 185%;
    }

    &::after {
      left: -30%;
    }

    h2 {
      position: absolute;
      bottom: 0;
      @include mq($until: xs) {
        position: static;
        margin-top: 10px;
      }
    }
  }

  &__list {
    margin-top: rem(55px);
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-row-gap: rem(30px);
    grid-column-gap: rem(80px);
    @include mq($until: md) {
      grid-column-gap: rem(40px);
    }
    @include mq($until: xs) {
      margin-top: rem(30px);
      grid-template-columns: 1fr;
    }
  }

  &__item {
    position: relative;

    h4 {
      font-size: rem(25px);
      text-transform: uppercase;
      transition: color .3s ease-in-out;
      margin-bottom: 0;
      line-height: 1;
      @include mq($until: xs) {
        font-size: rem(28px);
      }
    }

    span {
      font-size: rem(14px);
      font-weight: 400;
      max-width: 72%;
      display: block;
      @include mq($until: md) {
        max-width: unset;
      }
      @include mq($until: xs) {
        font-size: rem(16px);
      }
    }

    &:hover h4 {
      color: $color-main;
    }
  }
}

.h-assets {
  position: relative;
  display: flex;
  margin-bottom: rem(55px);

  &::before, &::after {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    background-size: auto 100%;
    background-repeat: no-repeat;
    z-index: 1;
  }

  &::before {
    background-image: url('../../images/homepage/sek3-left.jpg');
    background-position: left top;
    @include mq($until: xs){
      display: none;
    }
  }

  &::after {
    background-image: url('../../images/homepage/sek3-right.jpg');
    background-position: right top;
    @include mq($until: xs){
      background-size: cover;
    }
  }

  &__content {
    position: relative;
    z-index: 2;
    width: 40vw;
    background: linear-gradient(90deg, #ECF4F7 30%, rgba(255, 255, 255, 0) 100%);
    display: inline-block;
    height: 100%;
    padding-top: rem(180px);
    @include mq($until: md) {
      width: 100%;
      padding-top: rem(60px);
    }
    @include mq($until: xs) {
      padding-left: 0;
      padding-right: 0;
      background-image: none;
    }
  }

  &__header {
    @include mq($until: xs) {
      background-color: rgba(236, 244, 247, 0.8);
      padding: 15px;

      h2{
        margin-bottom: 0;
      }
    }
  }

  &__list {
    position: relative;
    width: 72vw;
    background-color: rgba(236, 244, 247, 0.8);
    margin-left: rem(-180px);
    padding-left: rem(180px);
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: rem(55px);
    padding-top: rem(40px);
    padding-bottom: rem(40px);
    @include mq($until: md) {
      width: 100%;
      margin-left: -100px;
      padding-left: 100px;
      padding-right: 15px;
    }
    @include mq($until: xs) {
      margin: 0;
      padding: 15px;
      grid-gap: 15px;
    }

    &::before, &::after {
      opacity: 0.4;
    }

    &::before {
      height: 115%;
    }

    &::after {
      right: -15%;
    }
  }

  &__item {
    display: flex;
    align-items: center;
    justify-content: flex-start;

    &:hover {
      &::before, &::after {
        opacity: 1;
      }
    }

    &::before, &::after {
      opacity: 0;
      transition: opacity .3s ease-in-out;
      @include mq($until: xs) {
        display: none;
      }
    }

    &::before {
      height: 130%;
      left: rem(82px);
      bottom: -45%;
    }

    &::after {
      left: -50%;
      bottom: -20%;
    }

    img {
      margin-right: rem(55px);
      max-width: rem(55px);
      @include mq($until: xs) {
        margin-right: 15px;
      }
    }

    span {
      font-size: rem(21px);
    }
  }
}

.h-realizations {
  margin-top: rem(130px);
  @include mq($until: xs) {
    margin-top: rem(90px);
  }

  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: rem(35px);
    @include mq($until: md) {
      padding-left: 0;
      padding-right: 0;
    }
    @include mq($until: xs) {
      flex-direction: column;
    }
  }

  &__list {
    display: flex;
    flex-direction: row;
    width: 100%;
    height: rem(585px);
    @include mq($until: md) {
      flex-direction: column;
      height: 180vw;
    }

    &:hover > .h-realizations__item:not(:hover) {
      @include mq($from: md) {
        &::after {
          background-color: rgba(255, 255, 255, 0.5);
        }
        h2 {
          opacity: 0;
        }
      }
    }
  }

  &__item {
    flex: 1;
    height: 100%;
    background-position: left center;
    background-size: cover;
    background-repeat: no-repeat;
    transition: all 0.8s ease;
    overflow: hidden;
    margin-left: 1px;
    margin-right: 1px;

    &::after {
      content: '';
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      background-color: rgba(0, 0, 0, 0.1);
      transition: background-color 0.5s ease-in-out;
    }

    @include mq($from: md) {
      &:hover {
        flex: 12;

        &::after {
          background-color: transparent;
        }
      }

      &:nth-of-type(1) {
        h2 {
          top: 25%;
        }
      }

      &:nth-of-type(2) {
        background-position: left 25% center;

        h2 {
          top: 50%;
        }
      }

      &:nth-of-type(3) {
        background-position: left 50% center;

        h2 {
          top: 5%;
        }
      }

      &:nth-of-type(4) {
        background-position: left 75% center;

        h2 {
          bottom: 5%;
          top: unset;
        }
      }
    }

    h2 {
      position: absolute;
      top: 10%;
      z-index: 9;
      color: #ffffff;
      font-size: rem(43px);
      font-weight: $fw-bold;
      text-shadow: 3px 3px 30px rgba(0, 0, 0, 0.5);
      line-height: 1;
      margin-left: 15px;
      max-width: rem(270px);
      transition: opacity 0.5s ease-in-out;
      @include mq($until: xs) {
        font-size: 16px;
        font-weight: 600;
      }
    }
  }
}

.h-staging {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  min-height: rem(900px);
  background-image: url('../../images/homepage/sek-4-bg.png');
  background-position: right top;
  background-size: cover;
  background-repeat: no-repeat;
  margin-bottom: rem(100px);
  margin-top: rem(170px);

  @include mq($until: md) {
    min-height: 50vw;
  }

  @include mq($until: sm) {
    min-height: 60vw;
  }

  @include mq($until: xs) {
    min-height: unset;
    margin-top: 80px;
    padding-bottom: 30px;
    padding-top: 30px;
  }

  &__content {
    max-width: rem(600px);
    @include mq($until: md) {
      padding-left: 0;
      padding-right: 0;
      max-width: 40vw;
    }
    @include mq($until: xs) {
      max-width: 100%;
      background-color: rgba(255, 255, 255, 0.7);
      padding: 10px;
    }

    p {
      font-size: rem(18px);
      font-weight: $fw-regular;
      color: #000;
      margin-bottom: rem(60px);
      @include mq($until: xs) {
        margin-bottom: 15px;
      }
    }
  }

  &__header {
    line-height: 1;

    span {
      display: block;
      font-size: inherit;
      line-height: 1;

      &:nth-of-type(2) {
        margin-left: 15px;
      }
    }
  }
}

.h-partnership {
  margin-bottom: 100px;

  @include mq($until: md) {
    margin-bottom: 50px;

    .padding-container {
      padding-left: 0;
      padding-right: 0;
    }
  }
}

.h-numbers {
  &__list {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    padding-bottom: rem(30px);
    padding-right: rem(30px);
    padding-left: rem(30px);

    @include mq($until: sm) {
      padding-left: 0;
      padding-right: 0;
    }

    @include mq($until: xs) {
      display: grid;
      grid-template-columns: 1fr;
      grid-gap: 15px;
      justify-content: center;
    }
    &::before, &::after {
      @include mq($until: xs){
        display: none;
      }
    }
    &::before {
      height: 200%;
      bottom: -100%;
      @include mq($until: sm) {
        right: -15px;
      }
    }

    &::after {
      right: -35%;
    }
  }

  &__item {
    font-size: rem(31px);
    @include mq($until: md) {
      font-size: rem(20px);
    }
    @include mq($until: xs) {
      justify-self: center;
      text-align: center;
    }

    b {
      font-size: rem(93px);
      color: $color-main;
      margin-left: rem(10px);
      margin-right: rem(10px);
      font-weight: $fw-medium;
      @include mq($until: md) {
        font-size: rem(73px);
      }

      &.tys {
        font-size: rem(60px);
        margin-left: 0;
        margin-right: 0;
        @include mq($until: md) {
          font-size: rem(35px);
        }
      }
    }
  }
}

.h-desc {
  margin-top: rem(65px);
  margin-left: rem(40px);
  margin-right: rem(40px);

  @include mq($until: xs){
    margin-top: 50px;
    margin-left: 0;
    margin-right: 0;
  }

  p {
    text-align: center;
    font-size: $fw-regular;
  }

  &__more {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: rem(50px);
    margin-bottom: rem(50px);
  }
}

.h-body {
  position: relative;
  z-index: 2;
  background-color: #ecf4f7;
}