.button {
  color: $color-font-dark;
  font-weight: $fw-medium;
  font-family: 'Teko', serif;
  font-size: rem(31px);
  padding: rem(20px) rem(20px);
  position: relative;
  line-height: 1;
  @include mq($until: xs){
    padding: 10px;
  }
  &::before, &::after{
    content: '';
    display: block;
    position: absolute;
    background-color: $color-main;
    transition: background-color .3s ease-in-out;
  }
  &::before{
    height: 100%;
    left: 0;
    width: 2px;
    bottom: -12%;
  }
  &:after{
    width: 85%;
    bottom: 6%;
    height: 2px;
    left: -6%;
  }

  &:hover {
    color: $color-main;
    &::before, &::after{
      background-color: #b7b7b7;
    }
  }

  &--secondary{
    &::before, &::after{
      background-color: #b7b7b7;
    }

    &:hover {
      color: $color-font-dark;
      &::before, &::after{
        background-color: $color-main;
      }
    }
  }
}