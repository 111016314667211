.strip {
  position: relative;

  &::before, &::after {
    content: '';
    display: block;
    position: absolute;
    background-color: $color-main;
    transition: background-color .3s ease-in-out;
  }

  &::before {
    height: 100%;
    width: 1px;
  }

  &:after {
    width: 100%;
    height: 1px;
  }

  &--right {
    &::before {
      right: 0;
    }
  }

  &--left {
    &::before {
      left: 0;
    }
  }

  &--top {
    &:after {
      top: 0;
    }
  }

  &--bottom {
    &:after {
      bottom: 0;
    }
  }

  &--orange {
    &::before, &::after {
      background-color: $color-main;
    }
  }

  &--dark {
    &::before, &::after {
      background-color: $color-font-dark;
    }
  }

  &--light {
    &::before, &::after {
      background-color: #ffffff;
    }
  }
}