.footer {
  background-color: $color-extra;
  display: grid;
  grid-template-columns: 1fr 1fr;
  padding-top: rem(55px);
  padding-bottom: rem(55px);
  margin-top: rem(50px);

  @include mq($until: xs) {
    grid-template-columns: 1fr;
  }

  h2, p, span, div, a {
    color: #ffffff;
  }

  &__contact, &__company {
    p {
      font-size: rem(19px);
      margin: 0;
      font-weight: $fw-light;
      letter-spacing: 0.5px;
      @include mq($until: md) {
        font-size: rem(16px);
      }
    }
  }

  &__company {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    @include mq($until: xs) {
      grid-row: 2;
      margin-top: 50px;
    }

    h2 {
      font-size: rem(31px);
      margin: 0;
      @include mq($until: md) {
        font-size: rem(22px);
      }
    }
  }

  &__right {
    justify-self: end;
    @include mq($until: xs){
      justify-self: start;
    }
  }

  &__menuList {
    padding-bottom: rem(25px);
    padding-left: rem(20px);
    margin: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__menuItem {
    padding: 0;
    margin: 0;

    &::before {
      display: none;
    }
  }

  &__menuLink {
    opacity: 0.3;
    transition: opacity .3s ease-in-out;
    font-size: rem(18px);
    font-weight: $fw-light;
    letter-spacing: 0.5px;

    @include mq($until: md) {
      font-size: rem(16px);
    }

    &:hover {
      opacity: 1;
    }
  }

  &__contact {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: rem(45px);
    grid-row-gap: rem(25px);
    grid-template-areas: 'welcome phone' 'open mail';
    align-items: center;
    padding-top: rem(25px);
    padding-left: rem(20px);

    &::before, &::after {
      opacity: 0.2;
    }

    &::before {
      top: -25px;
      width: 2px;
    }

    &::after {
      left: -25px;
      height: 2px;
    }
  }

  &__welcome {
    grid-area: welcome;
  }

  &__open {
    grid-area: open;
  }

  &__link {
    display: flex;
    align-items: center;
    justify-content: flex-start;

    span {
      transition: color .3s ease-in-out;
      font-family: 'Teko', serif;
      font-size: rem(25px);
      font-weight: $fw-regular;
      @include mq($until: md) {
        font-size: rem(20px);
      }
    }

    &:hover span {
      color: $color-main;
    }

    &--phone {
      grid-area: phone;
    }

    &--mail {
      grid-area: mail;
    }

    svg {
      fill: $color-main;
      margin-right: rem(15px);
      @include mq($until: md) {
        max-height: rem(30px);
        width: auto;
      }
    }
  }
}


.footer-small {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 15px;
  padding-bottom: 15px;
  @include mq($until: xs){
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  span, a {
    font-size: rem(12px);
    color: $color-font-dark;
  }

  a:hover {
    color: $color-main;
  }

  &__copyright{
    margin-top: 10px;
    margin-bottom: 15px;
  }

  &__realization {
    display: flex;
    justify-content: flex-end;
    align-items: center;

    span {
      transition: color .3s ease-in-out;

    }

    &:hover span {
      color: $color-main;
    }

    img {
      margin-left: rem(10px);
      max-width: rem(100px);
    }
  }
}