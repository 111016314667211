.project {

  &__slider {
    margin-bottom: 0;
    z-index: 2;
    height: 75vh;
    margin-top: 0;

    .slick-list, div, img, li {
      height: 100%;
    }

    li {
      margin-top: 0;
    }

    img {
      height: 100%;
      width: 100%;
      object-fit: cover;
    }
    @media (orientation: portrait) and (max-width:991px) {
      height: 35vh;
    }
  }

  &__contact {

    margin: 30px 0;

    a, span {
      font-family: 'Teko', serif;
      font-size: rem(23px);
    }

    a {
      display: flex;
      align-items: center;
      &:hover {
        color: $color-font-dark;
      }
    }

    span {
      display: block;
      transition: all .3s;
      padding-left: 13px;
      margin-left: 13px;
      &:hover {
        color: $color-main;
      }

      &::before {
        height: 130%;
        bottom: -30%;
      }

      &::after {
        width: 120%;
        left: -20%;
      }
    }
  }

  &__body {
    display: flex;

    @include mq($until: md) {
      flex-direction: column-reverse;
    }
  }

  h2 {
    margin: 2vw 0 1vw;
  }

  &__description {
    margin-right: 3.5vw;
  }

  &__text {
    width: 60%;
  }

  &__tabs {
    width: 40%;

    &-body {
      position: relative;
      background-color: white;
      width: 100%;
      height: calc(100% - 4vw);
    }

    &_switch {
      width: 100%;
      text-align: center;
      font-family: 'Teko', serif;
      font-weight: 600;
      padding: rem(40px) 0 rem(25px);
      cursor: pointer;
      transition: all .2s;

      &.active {
        
      }

      span {
        font-size: rem(19px);
      }
    }
  }

  &__tabs-header {
    display: flex;
  }

  &__tab {
      padding: 3vw;
      // display: none;
      opacity: 0;
      // position: absolute;
      width: 100%;
      transition: all .2s;

      &_second {
        position: absolute;
        top: 0;
      }

    li, ol, ul {
      margin: 0;
    }

    i {
      margin-right: 7px;
    }

    &.active {
      display: block;
    }

    @include mq($until: md) {
      padding: 6vw;

      ul li {
        margin-bottom: 5px;
      }
    }
  }

  .active {
    background-color: white;
    opacity: 1;
  }

  .slick-arrow {
    display: block;
    position: absolute;
    top: 50%;
    background-color: transparent;
    z-index: -1;
    height: 50px;
    width: 50px;
    border: 0;
    background-repeat: no-repeat;
    transition: all .4s;
    background-position: center;
    padding: 32px;

    &.strip {

      &::after {
        width: 200px;
        height: 2px;
      }
      &::before {
        height: 90px;
        top: 0;
        width: 2px;
      }

      &::after, &::before {
        background-color: #353535;
      }

      &:hover::after, &:hover::before {
        background-color: $color-main;
      }
    }


    &.slick-disabled {
        &:hover {
            color: rgb(223, 223, 223);
            border-color: rgb(223, 223, 223);
        }
    }

    &.slick-prev {
        left: initial;
        left: -5vw;
        background-image: url(./../../images/icons/left-b.svg);
        &:hover {
          background-image: url(./../../images/icons/left-o.svg);
        }

        &::after {
          left: -30px;
        }
    }

    &.slick-next {
        right: -5vw;
        background-image: url(./../../images/icons/right-b.svg);
        &:hover {
          background-image: url(./../../images/icons/right-o.svg);
        }

        &::after {
          right: -30px;
        }
    }
  }

  @include mq($until: lg) {

    .slick-arrow {
      margin: 18px;
      background-size: contain;
      height: 35px;
      width: 20px;
      padding: 0;
      &.slick-next {
        right: -4.5vw;
        &::after {
          bottom: -7px;
        }

        &::before {
          right: -7px;
          margin-top: -30%;
        }
      }

      &.slick-prev {
        left: -4.5vw;
        &::after {
          bottom: -7px;
        }

        &::before {
          left: -7px;
          margin-top: -30%;
        }
      }

      &.strip::after {
        width: 200px;
      }
    }
  }

  @include mq($until: md) {

    &__body {
      flex-direction: column-reverse;
    }

    &__description {
      margin-top: 25px;
      margin-right: 0;
      text-align: justify;
    }

    &__text {
      width: 100%;
      margin-top: 25px;
    }
  
    &__tabs {
      width: 100%;
    }
    
    &__contact a {
      flex-direction: column;
      span {
        margin-top: 25px;
        margin-left: 0;
      }
    }

    .slick-arrow {
      top: -60px;
      z-index: 5;

      &.slick-next {
        right: -1.5vw;

      }

      &.slick-prev {
        left: -1.5vw;
        &::after {
          bottom: -7px;
        }

        &::before {
          left: -7px;
          margin-top: -30%;
        }
      }

      &.strip::after {
        width: 100px;
      }
    }
  }

  @include mq($until: xs) {
    .slick-arrow {

      &.slick-next {
        right: -13px;
      }

      &.slick-prev {
        left: -13px;
      }

      &.strip::after {
        width: 100px;
      }
    }
  }
}

#line {
  width: 70%;
  height: 2px;
  background-color: $color-main;
  display: block;
  margin-bottom: 1.5vw;
}

.category {
  &__list {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 35px;

    img {
      height: 20vw;
      object-fit: cover;
      width: 100%;
      transition: all .3s;
    }
    
    a {
      width: 100%;
      h3 {
        transition: all .3s;
      }
      &:hover {
        
        h3 {
          color: $color-main;
        }

        img {
          transform: scale(1.01);
        }
      }
    }
  }

  @include mq($until: sm) {
    &__list {
      grid-template-columns: repeat(2, 1fr);
      gap: 25px;
      img {
        height: 35vw;
      }
    }
    h3 {
      margin-top: 15px;
    }
  }

  @include mq($until: xs) {
    &__list {
      margin-top: -50px;
      grid-template-columns: repeat(1, 1fr);

      img {
        height: 50vw;
      }
    }
  }
}