.header {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  min-height: rem(100px);
  padding-top: 2.7rem;
  position: fixed;
  width: 100%;
  top: 0;
  z-index: 10;
  transition: all .2s;

  &.sub {
    position: relative;

    @include mq($until: md) {
      flex-flow: wrap;
    }

    .header__hamburger {
      &:before, &:after {
        background-color: $color-font-dark;
      }

      span {
        color: $color-font-dark;
      }
    }
  }

  &__header {
    position: absolute;
    text-align: center;
    width: 100%;
    left: 0;
    h1 {
      font-size: rem(60px);
    }

    @include mq($until: md) {
      margin-top: 40px;
      position: static;
      h1 {
        font-size: rem(45px);
      }
    }

    @include mq($until: xs) {
      // margin-bottom: 4vh;
      margin-top: 5vh;
    }
  }

  @include mq($until: xs) {
    margin-bottom: 4vh;
  }

  @include mq($until: md){
    // position: static;
  }

  @include mq($until: sm){
    padding-top: 2.5rem;
  }

  &--fixed {
    position: fixed;
    width: 100%;
    transition: background-color .3s ease-in-out;
    z-index: 9;

    &.sticky {
      background-color: #ecf4f7;
    }
  }

  &__logo{
    position: relative;
    z-index: 2;
    max-width: 10.5rem;
    padding-right: rem(15px);
    padding-bottom: 4.2vh;
    box-sizing: content-box;
    &::before, &::after{
      opacity: 0.5;
    }
    &::before{
      height: 170%;
      top: -20%;
      @include mq($until: md){
        height: 140%;
      }
    }
    &::after{
      width: 700%;
      left: -300%;
    }

    @include mq($until: md){
      padding-bottom: 15px;
    }
  }

  &__nav {
    &--fullscreen {
      width: 100%;
      height: 100vh;
      background-color: #6c6c6cd6;
      position: absolute;
      top: 0;
      left: 0;
      z-index: 1;
      padding-top: 32vh;
      transform: translate(0, -100%);
      transition: transform 0.3s ease-in-out;
    }
    &--visible {
      transform: translate(0, 0);
    }
  }

  &__menuList {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin: 0;
    &--fullscreen {
      flex-direction: column;
    }
  }

  &__menuItem {
    margin-left: 10px;
    padding: 0;
    &--fullscreen{
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      margin-bottom: 30px;
    }
    &::before {
      display: none;
    }
  }

  &__menuLink {
    color: #3e3e3e;
    &--fullscreen {
      color: #ffffff;
      font-size: rem(28px);
    }
    &:active, &--active, &--open, &:hover {
      color: $color-main;
    }
  }
}