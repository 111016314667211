.header__hamburger {
  display: none;
  position: relative;
  justify-self: flex-end;
  cursor: pointer;
  padding: rem(3px) rem(6px);
  &::before, &::after{
    content: '';
    display: block;
    position: absolute;
    background-color: white;
    transition: background-color .3s ease-in-out;
  }
  &::before{
    height: 100%;
    right: 0;
    width: 2px;
    bottom: -12%;
  }
  &:after{
    width: 100%;
    bottom: 6%;
    height: 2px;
    right: -12%;
  }

  &:hover{
    &::before, &::after{
      background-color: $color-main;
    }
  }

  &--fullscreen {
    display: block;
    z-index: 2;
    margin-top: 20px;
  }

  span{
    color: white;
    font-family: 'Teko', serif;
    font-size: rem(19px);
    font-weight: $fw-regular;
    letter-spacing: 1px;
    display: inline-block;
    line-height: 1;
    transition: color .3s ease-in-out;
  }

  @media screen and (max-width: 991px) {
    display: block;
  }

  &--open {
    &::before, &::after{
      background-color: white!important;
    }
    span{
      color: white!important;
    }
  }
}

@media screen and (max-width: 991px) {
  .header {
    &__nav {
      position: absolute;
      width: 100%;
      top: 100%;
      right: 0;
      height: 100vh;
      background: $color-main;
      z-index: 3;
      transform-origin: 0 0;
      transform: translate(100%, 0);
      transition: transform .5s ease-in-out;
      padding-top: 15px;
      padding-bottom: 15px;

      &--visible {
        transform: translate(0, 0);
      }
    }

    &__menuLink {
      color: #fff;
    }

    &__menuList {
      flex-direction: column;
      padding: 10px 0;
      position: static;
      align-items: flex-start;

      & > .header__menuItem {
        display: block;
        margin: 0 0 15px 0;

        &--fullscreen {
          display: flex;
          margin: 0 0 25px 0;
        }

        &:last-child {
          margin-bottom: 0;
        }
      }
    }

    &__submenu {
      position: static;
      padding: 10px 0 0 0;
      margin-top: 5px;
      background-color: lighten($color-main, 10%);

      &--fullscreen {
        background-color: transparent;
        justify-content: center;
        align-items: center;
      }

      &--hidden {
        display: none;
      }

      .header__menuItem {
        margin: 0;
      }

      .header__menuLink {
        margin: 0 10px 10px 10px;
        display: inline-block;
      }
    }
  }
}

@media screen and (max-width: 767px) {

}

@media screen and (max-width: 575px) {

}