#fog2 {
    animation: MoveRight 7s linear infinite;
}

@keyframes MoveRight {

    0%, 100% {
        transform: translateX(0);
        opacity: 0;
    }

    40% {
        opacity: .9;
    }

    95% {
        transform: translateX(-20vw);
        opacity: 0;
    }

}