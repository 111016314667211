.subsite {

  h2 {
    font-size: rem(35px);
    padding-bottom: 20px;
    &:after {
      width: 50%;
      height: 2px;
      content: '';
      display: block;
      position: absolute;
      background-color: $color-main;
      margin: 10px 0;
    }
  }

  h3 {
    font-size: rem(27px);
  }

  h4 {
    font-size: rem(23px);
  }
  
}